<template>
  <el-container class="container-wrapper">
    <el-header :class="userName == 'admin' ? 'header_admin' : 'header'">
      <div class="logo">
        <h3 v-if="userName == 'admin'">短信管理平台</h3>
        <h3 v-else>短信平台</h3>
        <div class="logout">
          <span class="user_box">{{ userName }}</span>
          <el-button class="logout-btn" @click="logOut">退出</el-button>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside
        width="200px"
        :class="userName == 'admin' ? 'aside_admin' : 'aside'"
      >
        <el-menu
          unique-opened
          :default-active="$route.name"
          active-text-color="#ffd04b"
          :background-color="userName == 'admin' ? '#324157' : '#2484B6'"
          class="el-menu-vertical-demo"
          text-color="#fff"
          @select="changeTab"
        >
          <el-sub-menu
            v-show="item.isShow"
            v-for="(item, index) in menusList"
            :key="index"
            :index="index + ''"
          >
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.label }}</span>
            </template>
            <el-menu-item
              v-show="child.isShow"
              v-for="(child, chi) in item.children"
              :index="child.name"
              :key="chi"
            >
              <span>{{ child.label }}</span>
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script type="text/ecmascript-6">
import {
  Comment,
  Histogram,
  WalletFilled,
  Tools,
  Promotion,
} from "@element-plus/icons-vue";

export default {
  name: "home",
  components: {
    Comment,
    Histogram,
    WalletFilled,
    Tools,
    Promotion,
  },
  data() {
    return {
      userName: localStorage.getItem("userName"),
      activeTabName: "", // 当前显示的选项卡 激活的 name
      menusList: [
        {
          icon: "Comment",
          label: "短信",
          isShow: true,
          children: [
            {
              name: "messageAdd",
              label: "短信发送",
              isShow: true,
            },
            {
              name: "messageList",
              label: "模板管理",
              isShow: true,
            },
            {
              name: "messageTaskList",
              label: "短信任务",
              isShow: true,
            },
          ],
        },
        {
          icon: "Histogram",
          label: "统计中心",
          isShow: true,
          children: [
            {
              name: "messageChart",
              label: "短信统计",
              isShow: true,
            },
          ],
        },
        {
          icon: "Tools",
          label: "系统管理",
          isShow: true,
          children: [
            {
              name: "password",
              label: "修改密码",
              isShow: true,
            },
            {
              name: "chargeList",
              label: "充值记录",
              isShow:
                localStorage.getItem("userName") == "admin" ? true : false,
            },
            {
              name: "users",
              label: "用户列表",
              isShow:
                localStorage.getItem("userName") == "admin" ? true : false,
            },
          ],
        },
        // {
        //   icon: "Promotion",
        //   label: "闪信配置",
        //   isShow: localStorage.getItem("userName") == "admin" ? true : false,
        //   children: [
        //     {
        //       name: "flashList",
        //       label: "合作商配置",
        //       isShow:
        //         localStorage.getItem("userName") == "admin" ? true : false,
        //     },
        //   ],
        // },
      ],
    };
  },
  mounted() {
    this.activeTabName = this.$route.name;
  },
  methods: {
    // select	菜单激活回调	index: 选中菜单项的 index
    changeTab(name) {
      this.activeTabName = name;
      this.$router.push({
        name: name,
      });
    },
    // 退出登录
    logOut() {
      localStorage.clear();
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.header {
  height: 60px;
  position: relative;
  background-color: #166fb0;
}
.header_admin {
  height: 60px;
  position: relative;
  background-color: #303133;
}
.el-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-main {
  height: 100%;
  width: 100%;
  padding: 30px;
  margin: 0;
}
.logo_admin {
}
.logo > h3 {
  color: #ffffff;
  line-height: 60px;
  float: left;
  font-size: 22px;
  cursor: pointer;
}
.logout {
  line-height: 60px;
  position: absolute;
  top: 0;
  right: 30px;
  .user_box {
    color: #fff;
    margin-right: 30px;
  }
  .logout-btn {
    background: transparent;
    color: #fff;
  }
}
.el-aside {
  height: 100%;
}
.aside {
  background-color: #2484b6;
}
.aside_admin {
  background-color: #324157;
}
.el-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.el-submenu {
  width: 200px;
}
</style>
